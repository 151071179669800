import { SxStyleProp } from 'theme-ui';
import { newsletterStyles } from '../digital-experience/PDFForm.styles';

export const styles = {
  labelInput: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  } as SxStyleProp,
  emailInput: {
    variant: 'forms.textInput',
    width: '100%',
    border: '1px solid grey',
  },
  p: {
    my: 3,
    lineHeight: '185%',
  },
};

export const quizletterStyles = (withBackground = false, background = '#0E2A3A') => ({
  ...newsletterStyles(withBackground, background),
  img: {
    height: 'auto',
  },
  '.checkbox-paragraph': {
    color: 'background',
  },
});
